const fmtOptions = { style: "currency", currency: "EUR" };

export function formatCurrency(n: number | null | undefined) {
  if (n === null || n === undefined) {
    return "";
  }
  return n.toLocaleString
    ? n.toLocaleString(undefined, fmtOptions)
    : n.toFixed(2);
}
