import useSWR, { responseInterface } from "swr";
import { AutocompleteResults } from "../types";
import React, { useState } from "react";
import { uniqueId } from "lodash";

export const fetchAutocompleteResults = (url: string) =>
  fetch(url)
    .then((res) => res.json())
    .then((j: AutocompleteResults) => Array.from(j.results).filter(Boolean));
export const autocompleteSwrOptions = {
  revalidateOnFocus: false,
  revalidateOnMount: false,
};
export type AutocompleteSwr = responseInterface<string[], any>;

export interface AutocompleteDatalist {
  swr: AutocompleteSwr;
  element: React.ReactChild;
  id: string;
}

export function useAutocompleteSwr(url: string): AutocompleteSwr {
  return useSWR<string[]>(
    url,
    fetchAutocompleteResults,
    autocompleteSwrOptions
  );
}

export function useAutocompleteDatalist(url: string): AutocompleteDatalist {
  const swr = useAutocompleteSwr(url);
  const [datalistId] = useState(() => uniqueId("autocomplete"));
  const element = React.useMemo(
    () => (
      <datalist id={datalistId}>
        {(swr.data || []).map((s) => (
          <option value={s} key={s} />
        ))}
      </datalist>
    ),
    [datalistId, swr.data]
  );
  return {
    swr,
    element,
    id: datalistId,
  };
}
