import * as React from "react";
import { Wine } from "../types";
import { Organic, Vegan } from "../images";
import styled from "@emotion/styled";

const WineFlagImage = styled("img")({
  height: "1em",
});

export const WineFlags = ({ wine }: { wine: Wine }) => (
  <>
    {wine.eco ? <WineFlagImage alt="Organic" src={Organic} /> : null}
    {wine.vegan ? <WineFlagImage alt="Vegan" src={Vegan} /> : null}
  </>
);
