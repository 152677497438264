import { Wine, WineResults } from "../types";

export interface SearchState {
  maxPrice: number;
  maxSize: number;
  maxSugar: number;
  name?: string;
  origin?: string;
  grape?: string;
  store: string;
  type: string;
  veganOnly?: boolean;
}

function generateQueryString(ss: SearchState) {
  const qs = new URLSearchParams();
  if (ss.type) qs.append("type", ss.type);
  if (ss.store) qs.append("store", ss.store);
  if (ss.name) qs.append("name", ss.name);
  if (ss.origin) qs.append("origin", ss.origin);
  if (ss.grape) qs.append("grape", ss.grape);
  if (ss.maxPrice && !Number.isNaN(ss.maxPrice) && ss.maxPrice > 0)
    qs.append("max_price", `${ss.maxPrice}`);
  if (ss.maxSugar && !Number.isNaN(ss.maxSugar) && ss.maxSugar > 0)
    qs.append("max_sugar", `${ss.maxSugar}`);
  if (ss.maxSize && !Number.isNaN(ss.maxSize) && ss.maxSize > 0)
    qs.append("max_size", `${ss.maxSize}`);
  if (ss.veganOnly) qs.append("vegan", "1");
  qs.append("limit", "5000"); // TODO: Remove?
  return qs;
}

export async function doSearch(ss: SearchState): Promise<Wine[]> {
  const url = "/api/search?" + generateQueryString(ss).toString();
  const resp = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  const data: WineResults = await resp.json();
  return data.results;
}

export const initialState: SearchState = {
  maxPrice: 0,
  maxSize: 0,
  maxSugar: 0,
  name: "",
  origin: "",
  grape: "",
  store: "Turku",
  type: "",
  veganOnly: false,
};
export type SearchAction = { type: keyof SearchState; value: string };

export function searchReducer(
  state: SearchState,
  { value, type }: SearchAction
): SearchState {
  switch (type) {
    case "maxPrice":
    case "maxSize":
    case "maxSugar":
      return { ...state, [type]: parseFloat(value) };
    case "grape":
    case "name":
    case "origin":
    case "store":
    case "type":
      return { ...state, [type]: value };
    case "veganOnly":
      return { ...state, [type]: !!value };
    default:
      throw new Error();
  }
}
