import { interpolateRgbBasis } from "d3-interpolate";

const goodnessScale = [
  "#eb4d4b",
  "#e7caaf",
  "#ffffff",
  "#ffffff",
  "#ffffff",
  "#9fd58b",
  "#6ab04c",
];
const getGoodnessColor = interpolateRgbBasis(goodnessScale);

export function getGoodnessStyle(
  min: number | undefined,
  max: number | undefined,
  value: number
) {
  if (min !== undefined && max !== undefined && min !== max) {
    const goodness = (value - min) / (max - min);
    return { background: getGoodnessColor(goodness) };
  }
  return undefined;
}
