enum Translations {
  BUGREPORT = "Bug report/feedback",
  HEADER_ABV = "ABV%",
  HEADER_ACID = "Acid/l",
  HEADER_COUNT = "Count",
  HEADER_DATE = "Time",
  HEADER_ENERGY = "Energy kCal",
  HEADER_GRAPE = "Grape",
  HEADER_LITER_PRICE = "Liter Price",
  HEADER_MANUFACTURER = "Manufacturer",
  HEADER_NAME = "Name",
  HEADER_ORIGIN = "Origin",
  HEADER_PRICE = "Price",
  HEADER_PRICE_POINT = "Price Point Rating (-2..+2)",
  HEADER_PRICE_SCORE = "Score (€)",
  HEADER_QUALITY = "Quality Rating (0..5)",
  HEADER_SCORE = "Score",
  HEADER_SIZE_ML = "Size (ml)",
  HEADER_STORE_ID = "Store ID",
  HEADER_STORE_NAME = "Store",
  HEADER_SUBTYPE = "Subtype",
  HEADER_SUGAR = "Sugar/l",
  HEADER_TYPE = "Type",
  LABEL_HEART_REASON = "Heart",
  LABEL_REMARK = "Remarks",
  LOADING = "Loading",
  SEARCH_MAX_PRICE = "Max Price",
  SEARCH_MAX_SIZE = "Max Size",
  SEARCH_MAX_SUGAR = "Max Sugar",
  SEARCH_VEGAN_ONLY = "Vegan Only",
  SHOW_CARDS = "Show cards",
  SHOW_TABLE = "Show table",
  SORT_ALPHA = "Alphabetical",
  SORT_BEST_POINTS_FIRST = "Best points first",
  SORT_BEST_PRICE_SCORE_FIRST = "Best score x price first",
  SORT_BEST_SCORE_FIRST = "Best score first",
  SORT_BEST_STARS_FIRST = "Best stars first",
  SORT_CHEAP = "Cheapest first",
  SORT_DRIEST = "Driest first",
  SORT_EXPENSIVE = "Most expensive first",
  SORT_PERLITER_CHEAP = "Per-liter cheapest first",
  SORT_PERLITER_EXPENSIVE = "Per-liter most expensive first",
  SORT_SWEETEST = "Sweetest first",
}

export { Translations as T };
