import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./alko-styles.css";
import App from "./App";
import { isMobile } from "react-device-detect";

ReactDOM.render(
  <React.StrictMode>
    <App isMobile={isMobile || window.innerWidth < 768} />
  </React.StrictMode>,
  document.getElementById("root")
);
