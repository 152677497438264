import { Wine } from "../types";

export function getTypeClassName(wine: Wine) {
  const type = (wine.type || "").toLowerCase();
  if (type.includes("valkovii")) return "white-wine";
  else if (type.includes("punavii")) return "red-wine";
  else if (type.includes("kuohuvi")) return "spark-wine";
  else if (type.includes("rosee")) return "rose-wine";
  return "";
}

export function getSubtypeClassName(wine: Wine) {
  const type = (wine.subtype || "").toLowerCase();
  if (type.includes("mehevä")) return "taste-bg tasteStyle_003";
  else if (type.includes("kepeä")) return "taste-bg tasteStyle_006";
  else if (type.includes("lempeä")) return "taste-bg tasteStyle_007";
  else if (type.includes("pirteä")) return "taste-bg tasteStyle_008";
  else if (type.includes("kehittynyt")) return "taste-bg tasteStyle_004";
  else if (type.includes("vivahteikas")) return "taste-bg tasteStyle_009";
  else if (type.includes("runsas")) return "taste-bg tasteStyle_010";
  else if (type.includes("marjaisa")) return "taste-bg tasteStyle_001";
  else if (type.includes("pehmeä")) return "taste-bg tasteStyle_002";
  else if (type.includes("roteva")) return "taste-bg tasteStyle_005";
  return "";
}

export function getPointsClassName(wine: Wine) {
  if (wine.points_n < 0) return "stars-0";
  else if (wine.points_n >= 2) return "stars-5";
  else if (wine.points_n >= 1) return "stars-4";
  return "";
}
