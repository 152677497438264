import React from "react";
import { useAsync } from "react-async-hook";
import { ResultsTable } from "./components/ResultsTable";
import createPersistedReducer from "use-persisted-reducer";
import { doSearch, initialState, searchReducer } from "./services/search";
import { SearchBar } from "./components/SearchBar";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { ResultCards } from "./components/ResultCards";
import { LoadingIndicator } from "./components/LoadingIndicator";
import { MoreBar } from "./components/MoreBar";
import { useToggle } from "./hooks/useToggle";

const usePersistedReducer = createPersistedReducer("viinitarha-searchState");
const debouncedSearch = AwesomeDebouncePromise(doSearch, 1500);

function App({ isMobile }: { isMobile: boolean }) {
  const [searchState, changeSearchState] = usePersistedReducer(
    searchReducer,
    initialState
  );
  const search = useAsync(debouncedSearch, [searchState], {
    setLoading: (state) => ({ ...state, loading: true }),
  });
  const [showCards, showCardsAPI] = useToggle(isMobile);

  return (
    <div className="App">
      <MoreBar showCardsAPI={showCardsAPI} showCards={showCards} />
      <SearchBar
        searchState={searchState}
        changeSearchState={changeSearchState}
      />
      {search.loading ? <LoadingIndicator /> : null}
      {search.result ? (
        showCards ? (
          <ResultCards wines={search.result} pageSize={50} />
        ) : (
          <ResultsTable pageSize={isMobile ? 50 : 100} wines={search.result} />
        )
      ) : null}
    </div>
  );
}

export default App;
