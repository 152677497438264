import { Wine } from "../types";
import { AlkoLogo, ViinilehtiLogo } from "../images";
import React from "react";
import styled from "@emotion/styled";

interface ExtImageLinkProps {
  big: boolean;
}

const ExtImageLink = styled("a")<ExtImageLinkProps>(
  {
    display: "inline-block",
    ":hover": {
      background: "#eee",
    },
  },
  ({ big }) =>
    big
      ? { padding: "1em", img: { width: "3em", height: "3em" } }
      : { paddingLeft: "7px", img: { width: "1.75em", height: "1.75em" } }
);

export const WineExtLinks = ({
  wine,
  big,
}: ExtImageLinkProps & { wine: Wine }) => (
  <>
    <ExtImageLink
      big={big}
      href={wine.viinilehti_url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={ViinilehtiLogo} alt="Viinilehti" />
    </ExtImageLink>
    <ExtImageLink
      big={big}
      href={`https://www.alko.fi/tuotteet/${wine.alko_id}/`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={AlkoLogo} alt="Alko" />
    </ExtImageLink>
  </>
);
