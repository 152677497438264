import React from "react";
import { Wine } from "../types";
import { getSubtypeClassName, getTypeClassName } from "./utils";
import { formatCurrency } from "../utils";
import { AvailabilityTable } from "./AvailabilityTable";
import styled from "@emotion/styled";
import {
  candyEmoji,
  championEmoji,
  glassEmoji,
  grapeEmoji,
  heartEmoji,
  lemonEmoji,
  mapEmoji,
  scaleEmoji,
  starEmoji,
  zapEmoji,
} from "./emoji";
import { WineFlags } from "./WineFlags";
import { useToggle } from "../hooks/useToggle";
import { WineExtLinks } from "./WineExtLinks";

const ResultCardCtr = styled("div")({
  padding: ".5em",
  borderBottom: "1px solid #333",
  maxWidth: "800px",
  margin: "auto",
});
const MiniBarItem = styled("div")({
  padding: "5px",
  flex: 1,
  whiteSpace: "nowrap",
  fontSize: ".9em",
});
const TypeSpan = styled("span")({
  display: "inline-block",
  marginRight: "2px",
  padding: "2px",
  fontSize: ".9em",
});
const WineLink = styled("a")({
  marginTop: 0,
  marginBottom: "5px",
  fontSize: "1.25em",
  fontWeight: "bold",
  fontFamily: "oswald, sans-serif",
  color: "#186a89",
});
const TypesBlock = styled("div")({
  marginTop: "5px",
});
interface ResultCardProps {
  wine: Wine;
  sortOrder: string;
}

const RawResultCard = ({ wine, sortOrder }: ResultCardProps) => {
  const [showMore, { toggle: toggleMore }] = useToggle(false);

  let clickHandler = React.useCallback(
    (e) => {
      toggleMore();
      e.preventDefault();
      return false;
    },
    [toggleMore]
  );
  return (
    <ResultCardCtr>
      <div className="flex">
        <div className="flex-1" style={{ marginBottom: "2px" }}>
          <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <WineLink href="#" onClick={clickHandler}>
              {wine.name.replace(/hanapakkaus/, "hp")}
            </WineLink>{" "}
            <WineFlags wine={wine} />
          </div>
          <TypesBlock>
            <TypeSpan>
              {mapEmoji}
              {wine.origin.replace(/Muut/g, " ").trim().replace(/\t/g, ", ")}
            </TypeSpan>
            <TypeSpan>
              {grapeEmoji}
              {wine.grape.replace(/\t/g, ", ")}
            </TypeSpan>
            <TypeSpan className={getTypeClassName(wine)}>{wine.type}</TypeSpan>
            <TypeSpan className={getSubtypeClassName(wine)}>
              {wine.subtype}
            </TypeSpan>
          </TypesBlock>
        </div>
        <div style={{ textAlign: "right" }}>
          {formatCurrency(wine.price)} / {wine.size_ml} ml
          <br />({formatCurrency(wine.liter_price)} / l)
        </div>
      </div>
      <div className="flex" style={{ alignItems: "center" }}>
        <MiniBarItem style={{ background: "#ffd", textAlign: "center" }}>
          {championEmoji}{" "}
          {sortOrder.includes("agg_score_priced")
            ? wine.agg_score_priced.toFixed(3)
            : wine.agg_score}
        </MiniBarItem>
        <MiniBarItem style={{ background: "#fdf", textAlign: "center" }}>
          {starEmoji} {wine.heart_text ? heartEmoji : null} {wine.stars_n}
        </MiniBarItem>
        <MiniBarItem style={{ background: "#dff", textAlign: "center" }}>
          {scaleEmoji}️ {wine.points_n > 0 ? "+" : ""}
          {wine.points_n}
        </MiniBarItem>
        <MiniBarItem>
          {glassEmoji} {wine.abv} %
        </MiniBarItem>
        <MiniBarItem>
          {candyEmoji} {wine.sugar} g/l
        </MiniBarItem>
        <MiniBarItem>
          {lemonEmoji} {wine.acid} g/l
        </MiniBarItem>
        <MiniBarItem>
          {zapEmoji} {wine.energy} kCal
        </MiniBarItem>
        <MiniBarItem style={{ textAlign: "right", paddingRight: 0 }}>
          <WineExtLinks big={false} wine={wine} />
        </MiniBarItem>
      </div>
      {showMore ? <AvailabilityTable wine={wine} /> : null}
    </ResultCardCtr>
  );
};

export const ResultCard = React.memo(RawResultCard);
