import { Wine } from "../types";
import ReactTable from "react-table";
import React from "react";
import { numColWidth } from "../consts";
import { T } from "../i18n";

function getAvailabilityColumns() {
  const availabilityColumns = [
    {
      Header: T.HEADER_COUNT,
      accessor: "stock",
      maxWidth: numColWidth,
      className: "number-col",
    },
    {
      Header: T.HEADER_STORE_NAME,
      accessor: "store_name",
    },
    {
      Header: T.HEADER_STORE_ID,
      accessor: "store_id",
      maxWidth: numColWidth,
      className: "number-col",
    },
    {
      Header: T.HEADER_DATE,
      accessor: "time",
    },
  ];
  return availabilityColumns;
}

export const AvailabilityTable = ({ wine }: { wine: Wine }) => {
  const rows = wine.availability || [];
  return (
    <ReactTable
      data={rows}
      columns={getAvailabilityColumns()}
      showPagination={false}
      minRows={rows.length}
      defaultSorted={[
        {
          id: "stock",
          desc: true,
        },
      ]}
    />
  );
};
