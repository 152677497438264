import { Wine } from "../types";
import React from "react";
import ReactTable, { Column, RowInfo, SubComponentFunction } from "react-table";
import "react-table/react-table.css";
import { numColWidth, priceColWidth } from "../consts";
import { formatCurrency } from "../utils";
import {
  getPointsClassName,
  getSubtypeClassName,
  getTypeClassName,
} from "./utils";
import WineDetailPane from "./WineDetailPane";
import { ResultsComponentProps } from "./types";
import minMaxBy from "../utils/minMaxBy";
import { heartEmoji, scaleEmoji, starEmoji } from "./emoji";
import { WineFlags } from "./WineFlags";
import { getGoodnessStyle } from "../utils/goodness";
import { T } from "../i18n";

function getTableColumns(wines: Wine[]): Column<Wine>[] {
  const [minScore, maxScore] = minMaxBy<number>(wines, "agg_score");
  const [minScorePriced, maxScorePriced] = minMaxBy<number>(
    wines,
    "agg_score_priced"
  );
  const mainColumns: Column<Wine>[] = [
    {
      Header: T.HEADER_NAME,
      accessor: "name",
      width: 350,
    },
    {
      Header: T.HEADER_MANUFACTURER,
      accessor: "mfgr",
    },
    {
      Header: T.HEADER_TYPE,
      accessor: "type",
      getProps: ((x: any, rowInfo: RowInfo, column: Column) => {
        if (!rowInfo) return {};
        const wine = rowInfo.original as Wine;
        return { className: getTypeClassName(wine) };
      }) as any,
    },
    {
      Header: T.HEADER_SUBTYPE,
      accessor: "subtype",
      getProps: ((x: any, rowInfo: RowInfo, column: Column) => {
        if (!rowInfo) return {};
        const wine = rowInfo.original as Wine;
        return { className: getSubtypeClassName(wine) };
      }) as any,
    },
    {
      Cell: ({ original }) => {
        if (!original) return null;
        const wine = original as Wine;
        return <WineFlags wine={wine} />;
      },
      width: 35,
    },
    {
      Header: T.HEADER_SCORE,
      accessor: "agg_score",
      maxWidth: numColWidth,
      className: "score-col",
      getProps: ((x: any, rowInfo: RowInfo, column: Column) => {
        if (!rowInfo) return {};
        const wine = rowInfo.original as Wine;
        return { style: getGoodnessStyle(minScore, maxScore, wine.agg_score) };
      }) as any,
    },
    {
      Header: T.HEADER_PRICE_SCORE,
      accessor: "agg_score_priced",
      maxWidth: numColWidth * 1.5,
      className: "score-col",
      getProps: ((x: any, rowInfo: RowInfo, column: Column) => {
        if (!rowInfo) return {};
        const wine = rowInfo.original as Wine;
        return {
          style: getGoodnessStyle(
            minScorePriced,
            maxScorePriced,
            wine.agg_score_priced
          ),
        };
      }) as any,
    },
    {
      Header: <abbr title={T.HEADER_QUALITY}>{starEmoji}</abbr>,
      accessor: "stars_n",
      maxWidth: numColWidth,
      className: "score-col",
      Cell: ({ row, original }) => (
        <>
          {original.heart_text ? heartEmoji : ""} {row.stars_n}
        </>
      ),
      getProps: ((x: any, rowInfo: RowInfo, column: Column) => {
        if (!rowInfo) return {};
        const wine = rowInfo.original as Wine;
        return { className: `stars-${wine.stars_n}` };
      }) as any,
    },
    {
      Header: <abbr title={T.HEADER_PRICE_POINT}>{scaleEmoji}</abbr>,
      accessor: "points_n",
      maxWidth: numColWidth,
      className: "score-col",
      Cell: ({ row }) => (
        <>
          {row.points_n > 0 ? "+" : ""}
          {row.points_n}
        </>
      ),
      getProps: ((x: any, rowInfo: RowInfo, column: Column) => {
        if (!rowInfo) return {};
        const wine = rowInfo.original as Wine;
        let className = getPointsClassName(wine);
        return { className };
      }) as any,
    },
    {
      Header: T.HEADER_PRICE,
      accessor: "price",
      maxWidth: priceColWidth,
      className: "price-col",
      Cell: ({ row, original }) => <>{formatCurrency(row.price)}</>,
    },
    {
      Header: T.HEADER_LITER_PRICE,
      accessor: "liter_price",
      maxWidth: priceColWidth,
      className: "price-col",
      Cell: ({ row, original }) => <>{formatCurrency(row.liter_price)}</>,
    },
    {
      Header: T.HEADER_ORIGIN,
      accessor: "origin",
    },
    {
      Header: T.HEADER_SIZE_ML,
      accessor: "size_ml",
      maxWidth: numColWidth,
      className: "number-col",
    },
    {
      Header: T.HEADER_ABV,
      accessor: "abv",
      maxWidth: numColWidth,
      className: "number-col",
    },
    {
      Header: T.HEADER_SUGAR,
      accessor: "sugar",
      maxWidth: numColWidth,
      className: "number-col",
    },
    {
      Header: T.HEADER_ACID,
      accessor: "acid",
      maxWidth: numColWidth,
      className: "number-col",
    },
    {
      Header: T.HEADER_ENERGY,
      accessor: "energy",
      maxWidth: numColWidth,
      className: "number-col",
    },
  ];
  return mainColumns;
}

export const DetailComp: SubComponentFunction = (ri: RowInfo) => {
  // Yes, this is used as a function on purpose
  return WineDetailPane({ wine: ri.original as Wine });
};

const RawResultsTable = ({ wines, pageSize }: ResultsComponentProps) => {
  const mainColumns = React.useMemo(() => getTableColumns(wines), [wines]);
  return (
    <ReactTable
      data={wines}
      columns={mainColumns}
      pageSize={pageSize}
      showPageSizeOptions={false}
      defaultSorted={[
        {
          id: "agg_score",
          desc: true,
        },
      ]}
      SubComponent={DetailComp}
    />
  );
};

export const ResultsTable = React.memo(RawResultsTable);
