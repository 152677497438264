import React from "react";
import styled from "@emotion/styled";
import { ToggleAPI } from "../hooks/useToggle";
import { T } from "../i18n";
import useSWR from "swr";
import { Stats } from "../types";
import { formatRelative, parseISO } from "date-fns";

interface MoreBarProps {
  showCardsAPI: ToggleAPI;
  showCards: boolean;
}

const MoreBarCtr = styled("div")({
  background: "#30687d",
  padding: "5px 5px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "#96cde0",
  a: {
    color: "#fff",
  },
});

export const MoreBar = ({ showCards, showCardsAPI }: MoreBarProps) => {
  const statsSwr = useSWR<Stats>(
    "/api/stats",
    (url) => fetch(url).then((res) => res.json()),
    { revalidateOnFocus: false, revalidateOnMount: true }
  );
  return (
    <MoreBarCtr>
      <div>
        <a href="#" onClick={showCardsAPI.setOn}>
          {T.SHOW_CARDS}
        </a>
        {"\xA0\xA0"}
        <a href="#" onClick={showCardsAPI.setOff}>
          {T.SHOW_TABLE}
        </a>
      </div>
      <div>
        {statsSwr.data ? (
          <>
            {statsSwr.data.n_wines} wines. Availability from{" "}
            <span title={statsSwr.data.newest_availability_date}>
              {formatRelative(
                parseISO(statsSwr.data.newest_availability_date),
                new Date()
              )}
            </span>{" "}
          </>
        ) : null}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSd6vuO8GSLdJyji79I-DmHvL9kiTWZmptpp5ENGIpgVX6qINw/viewform?usp=sf_link"
          target="_blank"
        >
          {T.BUGREPORT}
        </a>
      </div>
    </MoreBarCtr>
  );
};
