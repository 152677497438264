import React from "react";

const renderEmoji = (emoji: string, title: string) => (
  <span aria-label={title} role="img">
    {emoji}
  </span>
);
export const mapEmoji = renderEmoji("🗺", "Map");
export const grapeEmoji = renderEmoji("🍇", "Grapes");
export const starEmoji = renderEmoji("⭐", "Stars");
export const heartEmoji = renderEmoji("💜", "Heart");
export const glassEmoji = renderEmoji("🍷", "ABV");
export const candyEmoji = renderEmoji("🍬", "Sugar");
export const lemonEmoji = renderEmoji("🍋", "Acid");
export const zapEmoji = renderEmoji("⚡", "Energy");
export const scaleEmoji = renderEmoji("⚖️", "Rating");
export const championEmoji = renderEmoji("🏆", "Score");
