import { SearchAction, SearchState } from "../services/search";
import React from "react";
import styled from "@emotion/styled";
import { MOBILE_MEDIA } from "../consts";
import {
  AutocompleteDatalist,
  useAutocompleteDatalist,
} from "../hooks/autocomplete";
import { T } from "../i18n";

interface SearchBarProps {
  searchState: SearchState;
  changeSearchState: (a: SearchAction) => void;
}

const Label = styled("label")({
  marginRight: "1em",
  display: "inline-block",
  "span.label": {
    marginRight: ".5em",
  },
  "span.suffix": {
    marginLeft: ".5em",
  },
  [MOBILE_MEDIA]: {
    marginRight: "0",
    display: "flex",
    alignItems: "center",
    marginBottom: "2px",
    "input[type=search]": {
      flex: 1,
    },
    "span.label": {
      padding: "2px",
      minWidth: "5.5em",
    },
  },
});

const SearchBarForm = styled("form")({
  position: "relative",
  padding: "2px 5px",
  background: "#97ccd7",
  "input[type=number]": {
    maxWidth: "5em",
    textAlign: "right",
  },
});

type TextSearchBoxProps = SearchBarProps & {
  label: React.ReactChild;
  stateKey: keyof SearchState;
  autocomplete?: AutocompleteDatalist;
};

function TextSearchBox({
  stateKey,
  label,
  searchState,
  changeSearchState,
  autocomplete,
}: TextSearchBoxProps) {
  const onFocus = React.useCallback(() => {
    if (
      autocomplete &&
      !autocomplete.swr.data &&
      !autocomplete.swr.isValidating
    ) {
      autocomplete.swr.revalidate();
    }
  }, [autocomplete]);
  const onChange = React.useCallback(
    (e) => {
      changeSearchState({ type: stateKey, value: e.target.value });
    },
    [stateKey, changeSearchState]
  );
  return (
    <Label>
      <span className="label">{label}:</span>
      <input
        type="search"
        value={searchState[stateKey]?.toString()}
        onChange={onChange}
        onFocus={onFocus}
        list={autocomplete?.id}
      />
      {autocomplete?.element}
    </Label>
  );
}

export function SearchBar(props: SearchBarProps) {
  const storeAutocomplete = useAutocompleteDatalist(
    "/api/autocomplete?field=store"
  );
  const originAutocomplete = useAutocompleteDatalist(
    "/api/autocomplete?field=origin"
  );
  const typeAutocomplete = useAutocompleteDatalist(
    "/api/autocomplete?field=type"
  );
  const grapeAutocomplete = useAutocompleteDatalist(
    "/api/autocomplete?field=grape"
  );
  const { searchState, changeSearchState } = props;
  return (
    <SearchBarForm>
      <TextSearchBox
        {...props}
        autocomplete={storeAutocomplete}
        label={T.HEADER_STORE_NAME}
        stateKey="store"
      />
      <TextSearchBox
        {...props}
        autocomplete={typeAutocomplete}
        label={T.HEADER_TYPE}
        stateKey="type"
      />
      <TextSearchBox
        {...props}
        autocomplete={originAutocomplete}
        label={T.HEADER_ORIGIN}
        stateKey="origin"
      />
      <TextSearchBox
        {...props}
        autocomplete={grapeAutocomplete}
        label={T.HEADER_GRAPE}
        stateKey="grape"
      />
      <TextSearchBox {...props} label="Name" stateKey="name" />
      <Label>
        <span className="label">{T.SEARCH_MAX_PRICE}:</span>
        <input
          type="number"
          min={0}
          value={searchState.maxPrice}
          onChange={(e) =>
            changeSearchState({ type: "maxPrice", value: e.target.value })
          }
        />
        <span className="suffix">€</span>
      </Label>
      <Label>
        <span className="label">{T.SEARCH_MAX_SUGAR}:</span>
        <input
          type="number"
          min={0}
          value={searchState.maxSugar}
          onChange={(e) =>
            changeSearchState({ type: "maxSugar", value: e.target.value })
          }
        />
        <span className="suffix">g/l</span>
      </Label>
      <Label>
        <span className="label">{T.SEARCH_MAX_SIZE}:</span>
        <input
          type="number"
          min={0}
          value={searchState.maxSize}
          onChange={(e) =>
            changeSearchState({ type: "maxSize", value: e.target.value })
          }
        />
        <span className="suffix">ml</span>
      </Label>
      <Label>
        <input
          type="checkbox"
          checked={searchState.veganOnly}
          onChange={(e) =>
            changeSearchState({
              type: "veganOnly",
              value: e.target.checked ? "1" : "",
            })
          }
        />
        {T.SEARCH_VEGAN_ONLY}
      </Label>
    </SearchBarForm>
  );
}
