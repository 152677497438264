import { Grapey } from "../images";
import React from "react";
import styled from "@emotion/styled";
import { T } from "../i18n";

const LoadingIndicatorCtr = styled("div")({
  position: "absolute",
  right: "1em",
  bottom: "1em",
  background: "rgba(255,255,255,0.9)",
  textAlign: "right",
  fontSize: "2em",
  zIndex: 69,
  pointerEvents: "none",
});

export function LoadingIndicator() {
  return (
    <LoadingIndicatorCtr>
      <img
        src={Grapey}
        className="loading-indicator-animation"
        style={{
          width: "4em",
        }}
        alt="Loading indicator"
      />
      <div>{T.LOADING}</div>
    </LoadingIndicatorCtr>
  );
}
