import { Wine } from "../types";
import React from "react";
import { AvailabilityTable } from "./AvailabilityTable";
import { T } from "../i18n";
import { WineExtLinks } from "./WineExtLinks";

const WineDetailPane = ({ wine }: { wine: Wine }) => {
  return (
    <div style={{ display: "flex", padding: "1em" }}>
      <div style={{ flex: 1, paddingRight: "1em" }}>
        <table style={{ width: "100%" }} className="detail-table">
          <tbody>
            {wine.heart_text ? (
              <tr>
                <td>{T.LABEL_HEART_REASON}</td>
                <td>{wine.heart_text}</td>
              </tr>
            ) : null}
            {wine.remark ? (
              <tr>
                <td>{T.LABEL_REMARK}</td>
                <td>{wine.remark}</td>
              </tr>
            ) : null}
            <tr>
              <td>{T.HEADER_ORIGIN}</td>
              <td>{wine.origin}</td>
            </tr>
            <tr>
              <td>{T.HEADER_GRAPE}</td>
              <td>{wine.grape}</td>
            </tr>
          </tbody>
        </table>
        <WineExtLinks big wine={wine} />
      </div>
      <div style={{ flex: 2 }}>
        <AvailabilityTable wine={wine} />
      </div>
    </div>
  );
};

export default WineDetailPane;
