import React from "react";
import { isNil, orderBy } from "lodash";
import usePagination, { PaginatedInterface } from "../hooks/usePagination";
import { ResultCard } from "./ResultCard";
import { ResultsComponentProps } from "./types";
import { Wine } from "../types";
import { T } from "../i18n";

const sortOrderOptions = [
  { value: "agg_score;desc", label: T.SORT_BEST_SCORE_FIRST },
  { value: "agg_score_priced;desc", label: T.SORT_BEST_PRICE_SCORE_FIRST },
  { value: "stars_n;desc", label: T.SORT_BEST_STARS_FIRST },
  { value: "points_n;desc", label: T.SORT_BEST_POINTS_FIRST },
  { value: "name;asc", label: T.SORT_ALPHA },
  { value: "price;asc", label: T.SORT_CHEAP },
  { value: "price;desc", label: T.SORT_EXPENSIVE },
  { value: "liter_price;asc", label: T.SORT_PERLITER_CHEAP },
  { value: "liter_price;desc", label: T.SORT_PERLITER_EXPENSIVE },
  { value: "sugar;asc", label: T.SORT_DRIEST },
  { value: "sugar;desc", label: T.SORT_SWEETEST },
];

const PaginationButtons = ({
  pagination,
}: {
  pagination: PaginatedInterface<Wine>;
}) => (
  <div>
    <button onClick={pagination.previousPage} disabled={pagination.page === 1}>
      &lt;
    </button>{" "}
    {pagination.page} / {pagination.totalPages}{" "}
    <button
      onClick={pagination.nextPage}
      disabled={pagination.page === pagination.totalPages}
    >
      &gt;
    </button>
  </div>
);

const RawResultCards = ({ wines, pageSize }: ResultsComponentProps) => {
  const [sortOrder, setSortOrder] = React.useState("agg_score;desc");
  const orderedWines = React.useMemo(() => {
    const [field, direction] = sortOrder.split(";");
    return orderBy(
      wines.filter((w: any) => !isNil(w[field])),
      [field],
      [direction as "asc" | "desc"]
    );
  }, [wines, sortOrder]);
  const pagination = usePagination(orderedWines, pageSize);
  return (
    <div>
      <div className="flex" style={{ margin: "5px" }}>
        <select
          onChange={(e) => {
            setSortOrder(e.target.value);
            pagination.firstPage();
          }}
          value={sortOrder}
          className="flex-1"
          style={{ marginRight: "1em" }}
        >
          {sortOrderOptions.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <PaginationButtons pagination={pagination} />
      </div>
      {pagination.paginated.map((wine, i) => (
        <ResultCard wine={wine} key={wine.alko_id} sortOrder={sortOrder} />
      ))}
      {pagination.totalPages > 1 ? (
        <div style={{ textAlign: "right" }}>
          <PaginationButtons pagination={pagination} />
        </div>
      ) : null}
    </div>
  );
};

export const ResultCards = React.memo(RawResultCards);
