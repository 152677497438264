import React from "react";

export interface ToggleAPI {
  setOn: () => void;
  toggle: () => void;
  setOff: () => void;
}

function toggleReducer(
  state: boolean,
  action: "toggle" | "true" | "false"
): boolean {
  switch (action) {
    case "toggle":
      return !state;
    case "true":
      return true;
    case "false":
      return false;
  }
  return state;
}

export function useToggle(initial: boolean): [boolean, ToggleAPI] {
  const [state, dispatch] = React.useReducer(toggleReducer, !!initial);
  const toggle = React.useCallback(() => dispatch("toggle"), [dispatch]);
  const setOn = React.useCallback(() => dispatch("true"), [dispatch]);
  const setOff = React.useCallback(() => dispatch("false"), [dispatch]);
  return [state, { toggle, setOn, setOff }];
}
