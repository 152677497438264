import { Wine } from "../types";

function minMaxBy<T>(
  wines: readonly Wine[],
  key: keyof Wine
): [T | undefined, T | undefined] {
  let min: T | undefined = undefined;
  let max: T | undefined = undefined;
  for (var i = 0; i < wines.length; i++) {
    const val: T | undefined = (wines[i][key] as unknown) as T; // Jesus take the typecast wheel!
    if (val !== undefined) {
      if (min === undefined || val < min) {
        min = val;
      }
      if (max === undefined || val > max) {
        max = val;
      }
    }
  }
  return [min, max];
}

export default minMaxBy;
